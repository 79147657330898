import React, { useState, useEffect, useContext } from 'react';
import booksapi from '../booksapi';
import { useParams, useNavigate } from "react-router-dom";
import Spinner from './Spinner';
import {LibraryContext} from '../libraryContext';

function EditAuthorForm() {
  const navigate = useNavigate();
  let { authorID } = useParams();
  const libraryID = useContext(LibraryContext);

  const [ isLoading, setLoaded ] = useState(true);
  const [ author, setAuthor ] = useState({});

  useEffect(() => {
    const fetchAuthor = async() => {
      const result = await booksapi.get('/authors/' + authorID, libraryID);
      setAuthor(result.data);
      setLoaded(false);
    };
    fetchAuthor();
  }, [ authorID, libraryID ]);

  function handleChange(event) {
    const { name, value } = event.target;
    let newAuthor = { ...author };
    newAuthor[name] = value;
    setAuthor(newAuthor);
  }

  function handleSubmit(event) {
    event.preventDefault();

    booksapi.post('/authors/' + authorID, author, libraryID)
    .then((response) => {
      navigate("/authors/" + authorID);
    }, (error) => {
      /** TODO handle errors */
      console.log(error);
    });
  }

  return (
    <>
    {isLoading ? (<Spinner/>)
    : (
      <><h1>Edit {author.authorDisplayName}</h1>
      <form id="authorForm" className="booksForm" onSubmit={handleSubmit}>
        <label htmlFor='authorForename'>Forename</label>
        <input className='form-control mb-2' name='authorForename' placeholder='e.g. Pelham' type='text' id='addbook-forename' value={author.authorForename} onChange={handleChange} data-lpignore="true"/>
        <label>Middle names</label>
        <input className='form-control mb-2' placeholder='e.g. Grenville' name='authorMiddleNames' type='text' id='addbook-middlenames' value={author.authorMiddleNames} onChange={handleChange} data-lpignore="true"/>
        <label>Surname</label>
        <input className='form-control mb-2' placeholder='e.g. Wodehouse' name='authorSurname' type='text' id='addbook-surname' value={author.authorSurname} onChange={handleChange} data-lpignore="true"/>
        <label>Display name</label>
        <input className='form-control mb-2' placeholder='e.g. PG Wodehouse' name='authorDisplayName' type='text' id='addbook-displayName' value={author.authorDisplayName} onChange={handleChange} data-lpignore="true"/>
        <button type='submit' className='btn-primary btn-lg btn-block' value='Save'>Save</button>
      </form>
      </>
    )}
    </>
  );
}

export default EditAuthorForm

import React, { useState } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

import { Helmet, HelmetProvider } from "react-helmet-async";
import Cookies from 'universal-cookie';
import './App.css';
import ViewAuthor from './components/ViewAuthor';
import AuthorList from './components/AuthorList';
import Book from './components/Book';
import CollectionList from './components/CollectionList';
import Collection from './components/Collection';
import NewAuthorForm from './components/NewAuthorForm';
import NewBookForm from './components/NewBookForm';
import EditAuthorForm from './components/EditAuthorForm';
import NewPublisherForm from './components/NewPublisherForm';
import NewCollectionForm from './components/NewCollectionForm';
import NewTagForm from './components/NewTagForm';
import TagList from './components/TagList';
import TagPage from './components/TagPage';
import HomePage from './components/HomePage';
import Header from './components/Header';
import {LibraryContext} from './libraryContext';

function App() {
  const helmetContext = {};
  const cookies = new Cookies();

  const [ libraryID, setLibraryID ] = useState(cookies.get('libraryID') ? Number.parseInt(cookies.get('libraryID')) : 1);

  const switchLibrary = (libraryID) => {
    cookies.set('libraryID', libraryID, { path: '/' });
    setLibraryID(libraryID);
  }

  return (
    <HelmetProvider context={helmetContext}>
    <LibraryContext.Provider value={libraryID}>
    <Router>
      {/*<Tracker/>*/}
      <Helmet
        defaultTitle="Books db"
        titleTemplate="Books db - %s">
      </Helmet>

      <Header/>
      <div className="container-xxl">
        <Routes>
          <Route path="/" element={<HomePage libraryID={libraryID} setLibraryID={switchLibrary}/>}/>
          <Route path="/authors" element={<AuthorList/>}/>
          <Route path="/authors/:authorID" element={<ViewAuthor/>}/>
          <Route path="/authors/:authorID/addbook" element={<NewBookForm/>}/>
          <Route path="/authors/:authorID/edit" element={<EditAuthorForm/>}/>
          <Route path="/authoredit/:authorID/edit" element={<EditAuthorForm/>}/>
          <Route path="/new/author" element={<NewAuthorForm/>}/>
          <Route path="/new/book" element={<NewBookForm/>}/>
          <Route path="/new/publisher" element={<NewPublisherForm/>}/>
          <Route path="/new/collection" element={<NewCollectionForm/>}/>
          <Route path="/new/tag" element={<NewTagForm/>}/>
          <Route path="/books/:bookID" element={<Book/>}/>
          <Route path="/collections" element={<CollectionList/>}/>
          <Route path="/collections/:collectionID" element={<Collection/>}/>
          <Route path="/tags" element={<TagList/>}/>
          <Route path="/tags/:tagID" element={<TagPage/>}/>
          <Route element={<div><h1>404</h1>You are nowhere</div>}/>
        </Routes>
      </div>
    </Router>
    </LibraryContext.Provider>
    </HelmetProvider>
  );
}

export default App;

import React, { useState, useEffect, useContext } from 'react';
import booksapi from '../booksapi';
import {
  Link
} from "react-router-dom";
import {Helmet} from "react-helmet-async";
import Spinner from './Spinner';
import { LibraryContext } from '../libraryContext';


const CollectionList = () => {
  const [ collections, setCollections ] = useState([]);
  const [ isLoading, setLoaded ] = useState(true);
  const libraryID = useContext(LibraryContext);

  useEffect(() => {
    const getCollections = async() => {
      const result = await booksapi.get('/collections', libraryID);
      setCollections(result.data);
      setLoaded(false);
    };
    getCollections();
  }, [ ]);

  return (
    <div id='collections'>
      {isLoading ? (<Spinner/>)
        : (<>
          <h1>Collections</h1>
          <section>
            <ul>
              {collections.map(collection => (
                <li key={collection.xyid}><Link to={`/collections/` + collection.xyid}>{collection.title}</Link></li>
              ))}
            </ul>

            <Helmet>
              <title>Collections</title>
            </Helmet>
          </section>
        </>)}
    </div>
  )
}

export default CollectionList

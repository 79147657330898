import React, { useState, useEffect, useContext } from 'react';
import booksapi from '../booksapi';
import { useParams } from "react-router-dom";
import AuthorSearch from './AuthorSearch';
import BookSearch from './BookSearch';
import TagSearch from './TagSearch';
import Spinner from './Spinner';

import {LibraryContext} from '../libraryContext';


const BookForm = props => {

    const tidyIncomingBook = (e) => {
      if(!e) e = {};
      if(!e.title) e.title = "";
      if(!e.subTitle) e.subTitle = "";
      if(!e.omnibus) e.omnibus = false;
      if(!e.firstPublished) e.firstPublished = "";
      if(!e.fiction) e.fiction = null;
      if(!e.type) e.type = null;
      if(!e.tags) e.tags = [];
      if(!e.subBooks) e.subBooks = [];

      return e;

    }
    const tidyIncomingAuthors = (a) => {
      if(!a) a = [];
      return a;
    }

    const [ isLoading, setLoaded ] = useState(true);
    const [ state, setState ] = useState(tidyIncomingBook(props.book));
    const [ authors, setAuthors ] = useState(tidyIncomingAuthors(props.authors));
    const libraryID = useContext(LibraryContext);

    let { authorID } = useParams();

    const handleChange = (event) => {
      const { name, value, checked, type } = event.target;
      let newState = { ...state };
      if(type === 'checkbox') {
          newState[name] = checked;
      }
      else {
        newState[name] = value
      }
      setState(newState);
      console.log(newState);
    }
    const handleNewTag = (tagID, tag) => {
      let newState = { ...state };
      newState.tags.push({tagID: tagID, tag: tag});
      setState(newState);
    };
    const removeTag =  i => {
      let newState = { ...state };
      newState.tags.splice(i, 1);
      setState(newState);
    }
    const handleNewSubBook = (bookID, title) => {
      let newState = { ...state };
      newState.subBooks.push({bookID: bookID, title: title});
      setState(newState);
    };
    const removeSubBook =  i => {
      let newState = { ...state };
      newState.subBooks.splice(i, 1);
      setState(newState);
    }

    const handleSubmit = (e) => {e.preventDefault(); props.onSubmit(state, authors);/**TODO */}
    const handleCancel = (e) => {e.preventDefault(); props.onCancel();/**TODO */}

    useEffect(() => {
      const fetchAuthors = async() => {
        let authorToFetch = authorID;
        if(!authorToFetch) { authorToFetch = props.authorID; }
        if(authorToFetch) {
          const authorDetails = (await booksapi.get('/authors/' + authorID, libraryID)).data;
          //handleNewAuthor(authorDetails.authorID, authorDetails.authorDisplayName);
          setAuthors([{authorID: authorDetails.authorID, authorDisplayName: authorDetails.authorDisplayName }]);
          setLoaded(false);
        }
        else {
          setLoaded(false);
        }
      };
      fetchAuthors();
    }, [ authorID, setAuthors, props.authorID ]);

    const handleNewAuthor = (authorID, authorDisplayName) => {
      setAuthors([...authors, {authorID: authorID, authorDisplayName: authorDisplayName }]);
    };

    const removeAuthor = i => {
      const list = [...authors];
      list.splice(i, 1);
      setAuthors(list);
    }

  return (
    <>
    { isLoading ? (<Spinner/>) :
      (<>
    <form id='addbook-form' className="booksForm" onSubmit={handleSubmit}>
      <button name='submit2' id='xxx' type='submit' className='defaultsink' value='Add Book'/>
      <label htmlFor='title'>Title</label>
      <input className='form-control mb-2' name='title' type='text' id='title' value={state.title} onChange={handleChange}/>

      <label htmlFor='subTitle'>Subtitle</label>
      <input className='form-control mb-2' name='subTitle' type='text' id='subTitle' value={state.subTitle} onChange={handleChange}/>

      <label htmlFor='omnibus'>Omnibus?</label>
      <div className='form-check form-switch'>
        <input className='form-check-input mb-2' name='omnibus' type='checkbox' id='omnibus' checked={state.omnibus} onChange={handleChange}/>
      </div>

      <label htmlFor='title'>SubBook(s)</label>
      <div>
        {state.subBooks.map((book, i) => {
          return (
            <span key={book.bookID}>{book.title} <button className='btn btn-secondary' onClick={() => removeSubBook(i)}>X</button></span>
          )
        })}
      </div>
      <BookSearch add={handleNewSubBook}/>

      <label htmlFor='author'>Author(s)</label>
      <div>
        {authors.map((author, i) => {
          return (
            <span key={author.authorID}>{author.authorDisplayName} <button className='btn btn-secondary' onClick={() => removeAuthor(i)}>X</button></span>
          )
        })}
      </div>
      <AuthorSearch add={handleNewAuthor}/>
      <div className='tags'>
        <label htmlFor='tags'>Tags</label> {state.tags.map((tag, i) => (
          <span className='tag' key={tag.tagID}>{tag.tag} <button className='btn btn-secondary' onClick={() => removeTag(i)}>X</button></span>
        ))}
      </div>
      <TagSearch add={handleNewTag}/>

      <label htmlFor='firstPublished'>First Published</label>
      <input className='form-control mb-2' name='firstPublished' type='number' min='1500' max='2050' id='firstPublished' value={state.firstPublished} onChange={handleChange}/>

      <label htmlFor='fiction'>Fiction</label>
      <div>
        <div className='form-check'>
          <label className='form-check-label'>Fiction</label>
          <input className='form-check-input'name='fiction' type='radio' checked={state.fiction === 'Fiction'} value='Fiction' onChange={handleChange}/>
        </div>
        <div className='form-check'>
          <label className='form-check-label'>Non-fiction</label>
          <input className='form-check-input'name='fiction' type='radio' checked={state.fiction === 'Non-fiction'} value='Non-fiction' onChange={handleChange}/>
        </div>
      </div>
      <label htmlFor='type'>Type</label>

      <div className='columns'>
        <div className='form-check'><label className='form-check-label'>Novel</label><input name='type' className='form-check-input' type='radio' checked={state.type === 'Novel'} value='Novel' onChange={handleChange}/></div>
        <div className='form-check'><label className='form-check-label'>Short stories</label><input name='type' className='form-check-input' type='radio' checked={state.type === 'Short stories'} value='Short stories' onChange={handleChange}/></div>
        <div className='form-check'><label className='form-check-label'><input name='type' className='form-check-input' type='radio' checked={state.type === 'Biography'} value='Biography' onChange={handleChange}/>Biography</label></div>
        <div className='form-check'><label className='form-check-label'><input name='type' className='form-check-input' type='radio' checked={state.type === 'Autobiography'} value='Autobiography' onChange={handleChange}/>Autobiography</label></div>
        <div className='form-check'><label className='form-check-label'><input name='type' className='form-check-input' type='radio' checked={state.type === 'Play'} value='Play' onChange={handleChange}/>Play</label></div>
        <div className='form-check'><label className='form-check-label'><input name='type' className='form-check-input' type='radio' checked={state.type === 'Poetry'} value='Poetry' onChange={handleChange}/>Poetry</label></div>
        <div className='form-check'><label className='form-check-label'><input name='type' className='form-check-input' type='radio' checked={state.type === 'Science'} value='Science' onChange={handleChange}/>Science</label></div>
        <div className='form-check'><label className='form-check-label'><input name='type' className='form-check-input' type='radio' checked={state.type === 'Reference'} value='Reference' onChange={handleChange}/>Reference</label></div>
        <div className='form-check'><label className='form-check-label'><input name='type' className='form-check-input' type='radio' checked={state.type === 'Book'} value='Book' onChange={handleChange}/>Book</label></div>
      </div>

      <button className='btn-primary btn-lg btn-block' name='cancel' value='Cancel' onClick={handleCancel}>Cancel</button>
      <button className='btn-primary btn-lg btn-block' name='submit' type='submit' value='Add Book' onClick={handleSubmit}>Save</button>
      </form>
    </>
    )
  }</>);
}

export default BookForm;

import React, { useContext } from 'react';
import booksapi from '../booksapi';
import { useNavigate } from "react-router-dom";
import CollectionForm from './CollectionForm';
import {LibraryContext} from '../libraryContext';


const NewCollectionForm = props => {
  const navigate = useNavigate();
  const libraryID = useContext(LibraryContext);

  const handleNewCollection = async (data) => {
    console.log(data);

    let collection = {
      title: data.title,
      criteria: data.criteria,
      editionTags: data.editionTags
    };

    collection.criteria.omnibus = collection.criteria.omnibus ? false : null;

    try {
      let response = await booksapi.post('/collections', collection, libraryID);
      const newCollection = response.data;
      console.log(newCollection);
      navigate("/collections/" + newCollection.xyid);
    }
    catch(error) {
      // TODO handle errors
      console.log(error);
    };
  }

  return (
    <>
      <h1>New collection</h1>
      <CollectionForm onSubmit={handleNewCollection} onCancel={(e) => { navigate(-1) }}/>
    </>
  );
}

export default NewCollectionForm

import React, { useContext } from 'react';
import booksapi from '../booksapi';
import { useNavigate } from "react-router-dom";
import BookForm from './BookForm';
import {LibraryContext} from '../libraryContext';


const NewBookForm = props => {
  const navigate = useNavigate();
  const libraryID = useContext(LibraryContext);

  const handleNeWBook = async (book, authors) => {
    book.authors = authors;
    book.editions = [];

    try {
      let response = await booksapi.post('/addBook', book, libraryID);
      const newBook = response.data;
      navigate("/books/" + newBook.bookID);
    }
    catch(error) {
      // TODO handle errors
      console.log(error);
    };
  }

  return (
    <>
      <h1>New book</h1>
      <BookForm authorID={props.authorID} onSubmit={handleNeWBook} onCancel={(e) => { navigate(-1) }}/>
    </>
  );
}

export default NewBookForm

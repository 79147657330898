import React, { useState, useEffect, useContext } from 'react';
import booksapi from '../booksapi';
import { Link,  useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Spinner from './Spinner';

import {LibraryContext} from '../libraryContext';


const ViewAuthor = () => {
  const [ author, setAuthor ] = useState({});
  const [ books, setBooks ] = useState([]);
  const [ isLoading, setLoaded ] = useState(true);
  const libraryID = useContext(LibraryContext);

  let { authorID } = useParams();

  useEffect(() => {
    const fetchAuthor = async () => {
      try {
        const result = await booksapi.get('/authors/' + authorID, libraryID);
        setAuthor(result.data);
        setLoaded(false);
      }
      catch(error) {
        console.log("Error getting data");
      }
    };
    fetchAuthor();

    const fetchBooks = async () => {
      const result = await booksapi.get('/authors/' + authorID + '/books', libraryID);
      setBooks(result.data);
      setLoaded(false);
    };
    fetchBooks();
  }, [ authorID ]);

  return (
    <div>
      {isLoading ? (<Spinner/>)
      : (<section>
        <h1>{author.authorDisplayName}</h1>
        <div>
          Full names: {author.authorForename} {author.authorMiddleNames} {author.authorSurname}
          <Link to={`/authors/${author.authorID}/edit`}>Edit author details</Link>
        </div>
        <h3>Books</h3>
          <div><Link to={`/authors/${author.authorID}/addbook`}>Add book</Link></div>
          <div className='columns'>
            <ul className='list-unstyled'>
              {books.map(book => (
                <li key={book.bookid}><Link className="link-dark" to={`/books/${book.bookid}`}>{book.title}</Link>{book.type === 'Short stories' ? <span className='short-stories'>SS</span> : ""}</li>
              ))}
            </ul>
          </div>
          <Helmet>
            <title>{author.authorDisplayName}</title>
          </Helmet>
        </section>)}
        </div>
    )
  }

export default ViewAuthor

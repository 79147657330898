import React, { useState } from 'react';

const DeleteButton = props => {
  const [ showConfirm, setShowConfirm ] = useState(false);

  const handleClick1 = () => {
    setShowConfirm(true);
  }
  const handleClick2 = () => {
    props.onClick();
  }

  return (
    <>
    <button className='btn icon-button' disabled={props.disabled} onClick={handleClick1}>
      <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-trash" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"></path>
        <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"></path>
        {props.disabled ? (<path fillRule="evenodd" d="M 0 16 L 16 0 Z" stroke="currentColor"/>) : ""}
      </svg>
    </button>
    {showConfirm ?
      (
        <div className='xxx'>
          <button className='btn btn-danger' onClick={handleClick2}>
            Delete?
          </button>
          <button className='btn btn-info' onClick={() => setShowConfirm(false)}>
            Cancel
          </button>
        </div>
      ) : ""}
      </>
  );
}

export default DeleteButton;

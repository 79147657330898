import React, { useState, useEffect } from 'react';
import booksapi from '../booksapi';
import { Helmet } from "react-helmet-async";
import Spinner from './Spinner';


const LibraryStats = ({ libraryID }) => {
  const [ library, setLibrary ] = useState({});
  const [ isLoading, setLoaded ] = useState(true);

  useEffect(() => {
    const fetchLibrary = async () => {
      if(libraryID && libraryID > 0) {
        const result = await booksapi.get('/library', libraryID);
        setLibrary(result.data);
        setLoaded(false);
      }
    };
    fetchLibrary();

  }, [ libraryID ]);

  return (
    <div>
      {isLoading ? (<Spinner/>)
      : (<section>
          <h3>Library stats</h3>
          <div><label><strong>Book count:</strong></label><span> {library.bookCount}</span></div>
          <div><label><strong>Owned book count:</strong></label><span> {library.ownedBookCount}</span></div>
          <div><label><strong>Edition count:</strong></label><span> {library.editionCount}</span></div>
          <div><label><strong>Owned edition count:</strong></label><span> {library.ownedEditionCount}</span></div>
          <div><label><strong>Author count:</strong></label><span> {library.authorCount}</span></div>
          <Helmet>
            <title>Books db - library</title>
          </Helmet>
        </section>)}
        </div>
    )
  }

export default LibraryStats

import React, { useState } from 'react';
import PublisherSearch from './PublisherSearch';
import ISBN from '../isbn';

const EditionForm = props => {

  const tidyIncomingEdition = (e) => {
    if(!e) e = {};
    if(!e.publishYear) e.publishYear = "";
    if(!e.publisherID) e.publisherID = "";
    if(!e.publisherName) e.publisherName = "";
    if(!e.penguinNumber) e.penguinNumber = "";
    if(!e.ISBN) e.ISBN = "";
    if(!e.editionTags) e.editionTags = [];

    if(!('owned' in e)) e.owned = true;
    //if(!e.owned) e.owned = true;
    return e;
  }
  const [ edition, setEdition ] = useState(tidyIncomingEdition(props.edition));

  const handleInputChange = (e) => {
    const { name, value, checked } = e.target;
    const newEdition = {...edition};
    if(e.target.type === 'checkbox') newEdition[name] = checked;
    else newEdition[name] = value;

    setEdition(newEdition);
  };

  const handleNewPublisher = (publisherID, publisherName) => {
    const newEdition = {...edition};
    newEdition.publisherID = publisherID;
    newEdition.publisherName = publisherName;
    setEdition(newEdition);
  }

  const handleCancel = (e) => {
    e.preventDefault();
    props.onCancel();
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    //validate()
    //pass to parent
    let newEdition = {...edition};
    if(newEdition.ISBN === "") newEdition.ISBN = null;
    if(newEdition.penguinNumber === "") newEdition.penguinNumber = null;
    if(newEdition.publishYear === "") newEdition.publishYear = null;
    props.onSubmit(newEdition);
  };

  document.onkeydown = (e) => {
    if(e.target.id === "ISBN" && e.keyCode === 13) {
      e.preventDefault();
      return false;
    }
  }

  return (
    <form className="booksForm" onSubmit={handleSubmit}>
      <button className='defaultsink' name='submit' value='Add edition' onClick={handleSubmit}>Add edition</button>

      <label htmlFor='publishername'>Publisher Name</label>
      <PublisherSearch
        add={(publisherID, publisherName) => handleNewPublisher(publisherID, publisherName)}
        publisherID={edition.publisherID}
        publisherName={edition.publisherName}/>

      <label htmlFor='publishYear'>Publish Year</label>
      <input className='form-control mb-2' name='publishYear' type='number' min='1500' max='2050' id='publishYear'
        value={edition.publishYear} onChange={handleInputChange}/>

      <label htmlFor='ISBN'>ISBN</label>
      <input className={"form-control mb-2 " + (ISBN.validate(edition.ISBN) ? "" : "is-invalid")} name='ISBN' type='text' id='ISBN'
        value={edition.ISBN} onChange={handleInputChange} data-lpignore="true" autoComplete="off"/>

      <label htmlFor='penguinNumber'>Penguin Number</label>
      <input className='form-control mb-2' name='penguinNumber' type='text' id='penguinNumber'
        value={edition.penguinNumber} onChange={handleInputChange} data-lpignore="true" autoComplete="off" pattern="[0-9]*"/>

      <label htmlFor='format'>Format</label>
      <select className='form-select mb-2' name='format' value={edition.format} onChange={handleInputChange}>
        <option value=''></option>
        <option value='Paperback'>Paperback</option>
        <option value='Hardback'>Hardback</option>
      </select>
      <div className='form-check form-switch'>
        <label htmlFor='owned'>Have Edition?</label>
        <input className='form-check-input' name='owned' type='checkbox' id='owned'
          checked={edition.owned} onChange={e => handleInputChange(e)} />
      </div>

      <div style={{display: 'flex', justifyContent: 'flex-end'}}>
        <button className='btn-secondary btn-lg' name='cancel' value='Cancel' onClick={handleCancel}>Cancel</button>
        <button className='btn-primary btn-lg' name='submit' value='Add edition' onClick={handleSubmit}>Save</button>
      </div>
    </form>
  )
}

export default EditionForm

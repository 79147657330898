import React, { useState, useRef, useContext } from 'react';
import booksapi from '../booksapi';

import SearchPanel from './SearchPanel';
import {LibraryContext} from '../libraryContext';

const BookSearch = props => {
  const libraryID = useContext(LibraryContext);

  const getBooks = async(search) => {
    const results = await booksapi.get('/search/books?term=' + search, libraryID);
    const nextBooks = [];
    for(let book of results.data) {
      nextBooks.push({key: book.value, value: book.label});
    }
    return nextBooks;
  }

  return (
    <SearchPanel add={props.add} getNextSearchResults={getBooks}/>
  );
}

export default BookSearch;

import React, {useState, useEffect} from 'react';
import AuthorSearch from './AuthorSearch';



const TagForm = props => {

    const tidyIncomingTag = (e) => {
      if(!e) e = {};

      if(!e.tag) e.tag = "";
      if(!e.authorSpecific) e.authorSpecific = false;
      if(!e.author) e.author = {};

      return e;
    }

    const [ state, setState ] = useState(tidyIncomingTag(props.tag));

    const handleChange = (event) => {
      const { name, value, checked, type } = event.target;
      let newState = { ...state };
      if(type === 'checkbox') {
          newState[name] = checked;
      }
      else {
        newState[name] = value
      }

      setState(newState);
      console.log(newState);

    }
    const handleSubmit = (e) => {e.preventDefault(); props.onSubmit(state); console.log(state);/**TODO */}
    const handleCancel = (e) => {e.preventDefault(); props.onCancel();/**TODO */}

    const handleNewAuthor = (authorID, authorDisplayName) => {
      let newState = {...state};
      newState.author = {authorID: authorID, authorDisplayName: authorDisplayName };
      newState.authorSpecific = true;
      setState(newState);
    };

    const removeAuthor = i => {
      let newState = {...state};
      newState.author = {};
      newState.authorSpecific = false;
      setState(newState);
    }


  return (
    <>
    <h1>New tag</h1>
    <form id='addcollection-form' className="collectionForm" onSubmit={handleSubmit}>
      <button name='submit2' id='xxx' type='submit' className='defaultsink' value='Add Collection'/>
      <label htmlFor='tag'>Tag Name</label>
      <input className='form-control mb-2' name='tag' type='text' id='tag' value={state.tag} onChange={handleChange}/>

      <label htmlFor='authorSpecific'>Author Specific?</label>
      <div className='form-check form-switch'>
        <input className='form-check-input mb-2' name='authorSpecific' type='checkbox' id='authorSpecific' checked={state.authorSpecific} onChange={handleChange}/>
      </div>


      <label htmlFor='title'>Author(s)</label>
      <div>
        {state.author.authorID ?
          <span key={state.author.authorID}>{state.author.authorDisplayName} <button className='btn btn-secondary' onClick={() => removeAuthor()}>X</button></span>
          : <AuthorSearch add={handleNewAuthor}/>}
      </div>

      <button className='btn-primary btn-lg btn-block' name='cancel' value='Cancel' onClick={handleCancel}>Cancel</button>
      <button className='btn-primary btn-lg btn-block' name='submit' type='submit' value='Add Book' onClick={handleSubmit}>Save</button>
      </form>
    </>);
}

export default TagForm;

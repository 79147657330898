import React, { useState, useRef, useContext } from 'react';
import booksapi from '../booksapi';
import SearchPanel from './SearchPanel';
import {LibraryContext} from '../libraryContext';

const TagSearch = props => {
  const libraryID = useContext(LibraryContext);

  const getTags = async(search) => {
    const results = await booksapi.get('/search/tags?term=' + search, libraryID);
    const nextTags = [];
    for(let tag of results.data) {
      nextTags.push({key: tag.value, value: tag.label});
    }
    return nextTags;
  }

  return (
    <SearchPanel add={props.add} getNextSearchResults={getTags}/>
  );
}

export default TagSearch;

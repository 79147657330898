import React, { useState } from 'react';
import booksapi from '../booksapi';
import TagSearch from './TagSearch';
import XButton from './XButton';
import PlusButton from './PlusButton';

const TagsGroup = props => {
  const [ addingTags, setAddingTags] = useState(false);

  const catchAddTag = (tagID, tag) => {
    props.addTag(tagID, tag);
    setAddingTags(false);
  }
  return (
    <div className='tags'>
      Tags:
      {props.tags.map(tag => (
        <span className='badge bg-secondary' key={tag.tagID}>{tag.tag} <XButton className='icon-button2' onClick={() => props.deleteTag(tag.tagID)}/></span>
      ))}
        {addingTags ?
          <span className='badge bg-light d-block'>
            <TagSearch add={catchAddTag}/>
          </span> :

          <span className='badge bg-light'>
            <PlusButton onClick={() => setAddingTags(true)}/>
          </span>
        }
    </div>

    )
  }

export default TagsGroup

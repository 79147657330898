import React, { useState, useContext } from 'react';
import booksapi from '../booksapi';
import { useNavigate } from "react-router-dom";
import {LibraryContext} from '../libraryContext';


function NewPublisherForm() {const navigate = useNavigate();
  const libraryID = useContext(LibraryContext);

  const [ state, setState ] = useState({publisherName: ""});

  function handleChange(event) {
    const { name, value } = event.target;
    let newState = { ...state };
    newState[name] = value;
    setState(newState);
  }

  function handleSubmit(event) {
    console.log(event);
    console.log(state);
    event.preventDefault();

    booksapi.post('/publishers', state, libraryID)
    .then((response) => {
      console.log(response);
      navigate("/");

    }, (error) => {
      /** TODO handle errors */
      console.log(error);
    });
  }

  return (
    <>
    <h1>New publisher</h1>
    <form id="publisherForm" className="booksForm" onSubmit={handleSubmit}>
      <label>Publisher Name</label>
      <input className='form-control mb-2' name='publisherName' placeholder='Publisher' type='text' id='publisherName' onChange={handleChange} data-lpignore="true"/>
      <button className='btn-primary btn-lg btn-block' type='submit' value='Add publisher' id=''>Add publisher</button>
    </form>
    </>
  );
}

export default NewPublisherForm

import React, { useState, useEffect, useContext } from 'react';
import booksapi from '../booksapi';
import {
  Link,
  useParams
} from "react-router-dom";
import {Helmet} from "react-helmet-async";
import Spinner from './Spinner';
import TagForm from './TagForm';
import {LibraryContext} from '../libraryContext';


const Tag = () => {
  const [ tag, setTag ] = useState({});
  const [ isLoading, setLoaded ] = useState(true);
  const [ editTag, setEditTag ] = useState(false);
  /*const [ showAddEdition, setShowAddEdition ] = useState(false);
  const [ editBook, setEditBook ] = useState(false);*/
  const libraryID = useContext(LibraryContext);

  let { tagID } = useParams();

  const handleNewTag = async (newTag) => {
    await booksapi.post('/tags/' + tagID, newTag, libraryID);
    setTag(newTag);
    setEditTag(false);
  }

  useEffect(() => {
    const fetchTag = async () => {
      const result = await booksapi.get('/tags/' + tagID, libraryID);
      console.log(result.data);
      setTag(result.data);
      setLoaded(false);
    };
    fetchTag();
  }, [ tagID ]);

  return (
    <>
    {isLoading ? (<Spinner/>)
    : (
      <>
        <section>
          <h1>{tag.tag}</h1>
          {tag.authorSpecific ? (<>Specific to <Link to={`/authors/${tag.author.authorID}`}>{tag.author.authorDisplayName}</Link></>) : ""}
          <br/>
          <button className='btn-primary btn-lg' disabled={editTag} onClick={() => setEditTag(true)}>Edit</button>

          {editTag ?
            (<TagForm tag={tag} onSubmit={handleNewTag} onCancel={() => setEditTag(false)}/>)
            : ("")
          }

        </section>
      <Helmet>
        <title>{tag.tag}</title>
      </Helmet>
      </>
    )}
    </>
  )
}

export default Tag

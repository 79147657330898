import React, { useState, useContext } from 'react';
import { Link } from "react-router-dom";
import booksapi from '../booksapi';
import EditionForm from './EditionForm';
import TagsGroup from './TagsGroup';
import DeleteButton from './DeleteButton';
import EditButton from './EditButton';
import { LibraryContext } from '../libraryContext';

const EditionTile = props => {
  const [ isDeleted, setDeleted ] = useState(false);
  const [ editing, startEditing ] = useState(false);
  const edition = props.edition;
  console.log(edition);
  const libraryID = useContext(LibraryContext);

  const deleteEdition = () => {
    console.log(edition);
    let response = booksapi.delete('/books/' + edition.bookID + '/editions/'  + edition.bookEditionID, libraryID);
    console.log(response);
    setDeleted(true);
  }

  const editEdition = () => {
    startEditing(true);
  }
  const handleCancel = () => {
    startEditing(false);
  }
  const handleChangedEdition = (newEdition) => {
    props.handleChangedEdition(newEdition, edition.bookEditionID);
    startEditing(false);
  }

  const handleNewTag = (tagID, tag) => {
    booksapi.post('/books/' + edition.bookID + '/editions/' + edition.bookEditionID + '/tags/' + tagID, {}, libraryID)
    .then((response) => {
      let newEdition = {...edition};
      if(!('editionTags' in newEdition)) newEdition.editionTags = [];
      newEdition.editionTags.push({tagID: tagID, tag: tag});

      props.handleUpdatedEdition(newEdition, edition.bookEditionID);
      // clear form, and hide by telling parent
    }, (error) => {
      // TODO handle errors
      console.log(error);
    });
  }
  const deleteTag = async (tagID, tag) => {
    await booksapi.delete('/books/' + edition.bookID + '/editions/' + edition.bookEditionID + '/tags/' + tagID, libraryID);
    let newEdition = {...edition};
    newEdition.editionTags = newEdition.editionTags.filter(x => x.tagID !== tagID);
    props.handleUpdatedEdition(newEdition, edition.bookEditionID);
  }

  return (
    <>
    {isDeleted ? false :
      editing ? (<EditionForm edition={edition} onSubmit={handleChangedEdition} onCancel={handleCancel}/>) :
      (<div className='edition card'>

      <div className="card-header">
        {edition.omnibus ? "" :
          <div className='editionButtons'>
            <EditButton disabled={false} onClick={editEdition}/>
            <DeleteButton disabled={false} onClick={deleteEdition}/>
          </div>
        }
        {edition.publisherName}
      </div>
      <div className='card-body'>
        {edition.omnibus ? (<span className='edition-omnibus'><em>in</em> <Link to={`/books/${edition.parentBook}`}>{edition.parentTitle}</Link></span>) : false }
        <span className='edition-publisher'>{edition.publisherName ? edition.publisherName : "unknown"}</span>
        <span className='edition-publishYear'>{edition.publishYear ? edition.publishYear : "unknown"}</span>
        {edition.ISBN ? (<span className='edition-ISBN'>ISBN:{edition.ISBN}</span>) : false}
        {edition.penguinNumber ? (<span className='edition-penguinNumber'>{edition.penguinNumber}</span>) : false}
        {edition.format ? (<span className='edition-format'>{edition.format}</span>) : false}
          {edition.owned ? ("Owned") : ("Wanted")}
          {edition.editionTags ? (
            <TagsGroup tags={edition.editionTags} deleteTag={deleteTag} addTag={handleNewTag}/>
            ) : false}
        </div>
      </div>
    )}
    </>
  );
}

export default EditionTile;

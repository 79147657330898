import React, { useState, useRef } from 'react';
import booksapi from '../booksapi';


const SearchPanel = props => {
  const [ search, setSearch ] = useState("");
  const [ searchResults, setSearchResults ] = useState([]);
  const inputBox = useRef();
  const searchResultList = useRef();
  const searchPanel = useRef();

  async function handleChange (e) {
    const value = e.target.value;
    setSearch(value);
    if(value.length >= 2) {
      setSearchResults(await props.getNextSearchResults(value));
    }
    else {
      setSearchResults([]);
    }
    scrollList();
  }

  function scrollList() {
    let first = searchResultList.current.firstChild; // targets the first <li>
    searchPanel.current.onkeydown = function(e) { // listen to keyboard events
      switch (e.keyCode) {
        case 38: // if the UP key is pressed
          if (e.target === inputBox.current) { break; }
          else if(document.activeElement === first) {
            inputBox.current.focus();
          }
          else { document.activeElement.previousSibling.focus(); } // select the element before the current, and focus it
            e.preventDefault();
          break;
        case 40: // if the DOWN key is pressed
          if (document.activeElement === inputBox.current && first !== null) { first.focus(); } // if the currently focused element is the main input --> focus the first <li>
          else if(document.activeElement.nextSibling) {
            document.activeElement.nextSibling.focus();
          }
          else {
            break;
          }
          e.preventDefault();
          break;
        case 13: // enter
          if(e.target.parentNode === searchResultList.current) {
            e.target.firstChild.click();
            if(inputBox.current) inputBox.current.focus();
          }
          else if(e.target === inputBox.current && searchResultList.current.childElementCount === 1) {
            searchResultList.current.firstChild.firstChild.click();
            if(inputBox.current) inputBox.current.focus();
          }
          e.preventDefault();
          break;
          default:
          break;
        }
      }
    }

    function pick(key, value) {
      props.add(key, value);
      setSearchResults([]);
      setSearch("");
    }

    return (
      <div ref={searchPanel}>
        <input ref={inputBox} className='form-control mb-2' type='text' id='search' value={search} placeholder="Start typing.." onChange={handleChange} autoComplete='off'/>

          <ul ref={searchResultList} className='searchList'>
            {searchResults.map((item) => {
              return (
                <li key={item.key} tabIndex='0'>
                  <button className='btn btn-secondary' onClick={() => pick(item.key, item.value)}>
                    {item.value}
                  </button>
                </li>)
              })}
          </ul>
      </div>
    );
  }

  export default SearchPanel;

import React, { useState, useRef, useContext } from 'react';
import booksapi from '../booksapi';
import {LibraryContext} from '../libraryContext';


const NewPublisherModal = (props) => {
  const [ state, setState ] = useState({publisherName: ""});
  const modalClose = useRef();
  const libraryID = useContext(LibraryContext);

  function handleChange(event) {
    const { name, value } = event.target;
    let newState = { ...state };
    newState[name] = value;
    setState(newState);
  }

  function handleSubmit(event) {
    event.preventDefault();

    booksapi.post('/publishers', state, libraryID)
    .then((response) => {
      /** pass newly created publisher back to the parent element */
      props.add(response.data.publisherID, response.data.publisherName);

      /** clear form state, and close modal by simulating click on close button */
      setState("");
      modalClose.current.click();
    }, (error) => {
      /** TODO handle errors */
      console.log(error);
    });
  }

  const redirectEnter = (e) => {
    if(e.keyCode === 13)  {
      e.preventDefault();
      handleSubmit(e);
    }
  }

  return (
    <>
    <button id='x' type="button" className="btn btn-primary" data-toggle="modal" data-target="#staticBackdrop">
      Add new publisher
    </button>

    <div className="modal fade" id="staticBackdrop" data-backdrop="static" data-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">New publisher</h5>
            <button ref={modalClose} type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <label>Publisher Name</label>
            <input className='form-control mb-2' name='publisherName' placeholder='Publisher' type='text' id='publisherName' onChange={handleChange} onKeyDown={redirectEnter} data-lpignore="true"/>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
            <button type="button" className="btn btn-primary" onClick={handleSubmit}>Add publisher</button>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default NewPublisherModal

import React, { useState, useEffect, useContext } from 'react';
import booksapi from '../booksapi';
import { Link, useNavigate, useParams } from "react-router-dom";
import {Helmet} from "react-helmet-async";
import Emoji from '../Emoji';
import Spinner from './Spinner';
import DeleteButton from './DeleteButton';
import EditButton from './EditButton';

import CollectionForm from './CollectionForm';
import { LibraryContext } from '../libraryContext';

const Collection = () => {
  const navigate = useNavigate();

  const [ collection, setCollection ] = useState([]);
  const [ isLoading, setLoaded ] = useState(true);
  const [ editCollection, setEditCollection ] = useState(false);
  const libraryID = useContext(LibraryContext);

  let { collectionID } = useParams();

  const tidyCollection = (collection) => {
    collection.books.sort((a,b) => a.firstPublished - b.firstPublished);

    let targetTags = collection.editionTags;

    collection.books.forEach(book => {
      book.tags =
        targetTags.map((targetTag) => {
          let x = book.editions.map((edition) => {
            let y = edition.editionTags.filter((tag) => tag.tagID === targetTag.tagID );
            if(y.length > 0 && edition.owned) return 2;
            else if(y.length === 0 && targetTag.required) return 0;
            else return 1;
          });
          return Math.max(...x, targetTag.required ? 0 : 1);
        });
    });
    return collection;
  }

  useEffect(() => {
    const getCollection = async() => {
      const result = await booksapi.get('/collecting/' + collectionID, libraryID); // TODO fix lknks

      setCollection(tidyCollection(result.data));
      setLoaded(false);
    };
    getCollection();
  }, [ collectionID, libraryID ]);

  const handleNewCollection = async (newCollection) => {
    let newSpec = {
      title: newCollection.title,
      criteria: newCollection.criteria,
      /*{
        authors: newCollection.authors,
        bookTags: newCollection.bookTags,
        publishedBefore: newCollection.publishedBefore,
        publishedAfter: newCollection.publishedAfter
      },*/
      editionTags: newCollection.editionTags
    };

    newSpec.criteria.omnibus = newSpec.criteria.omnibus ? false : null;
    console.log(newSpec);
    let response = await booksapi.post('/collecting/' + collectionID, newSpec, libraryID);
    setCollection(tidyCollection(response.data));
    setEditCollection(false);
  }

  const deleteCollection = async () => {
    await booksapi.delete('/collecting/' + collectionID, libraryID);
    navigate("/collections");
  }

  return (
    <div>
    {isLoading ? (<Spinner/>)
    : (<section>
      <h1 >
        {collection.title}
        <EditButton disabled={editCollection} onClick={() => setEditCollection(true)}/>
        <DeleteButton disabled={editCollection} onClick={() => deleteCollection(true)}/>
      </h1>
      { editCollection ?
        (<CollectionForm collection={collection} onSubmit={handleNewCollection} onCancel={() => setEditCollection(false)}/>)
        : ""
      }
      <table className="collection-table table table-hover table-sm">
        <thead><tr><th>Title</th><th>First pub.</th><th>Owned</th><th>Standalone</th>
        {collection.editionTags.map((tag, i) => (<th key={tag.tagID}>{tag.tag}</th>)) }
        </tr></thead>
        <tbody>
        {collection.books.map(book => (
            <tr key={book.bookID}>
              <td><Link className="link-dark text-decoration-none" to={`/books/${book.bookID}`}>{book.title}</Link>{book.type === 'Short stories' ? <span className='short-stories'>SS</span> : ""}</td>
              <td>{book.firstPublished}</td>
              <td>{book.ownedEditionCount}</td>
              <td>{book.ownedStandaloneEditionCount}</td>
              {book.tags.map((tag, i) => (<td key={i}>{tag === 2 ? (<Emoji symbol="✅" label="Yes"/>) : tag === 1 ? (<Emoji symbol="❌" label="No"/>) : ("-")}</td>)) }
            </tr>
          ))}
          </tbody>
      </table>

      <Helmet>
        <title>{collection.title}</title>
      </Helmet>
    </section>)}
    </div>
  )
}

export default Collection


function calculateISBN10CheckDigit(input = "") {
  if(!input || input.length !== 9) throw Error("invalid input");
  let calc = Array.from(input).reduce((sum, x, i) => {
    return sum + ((i + 1) * Number(x));
  }, 0) % 11;
  return calc === 10 ? "X" : String(calc);
}

function calculateISBN13CheckDigit(input = "") {
  if(!input || input.length !== 12) throw Error("invalid input");

  let calc = Array.from(input).reduce((sum, x, i) => {
    return sum + ((i % 2) === 0 ? Number(x) : (3 * Number(x)));
  }, 0);
  return (10 - (calc % 10)) % 10;
}

function validate(input = "") {
  if(!input) return false;
  if(input.length === 0) return true;
  else if(input.length === 10) return (calculateISBN10CheckDigit(input.substring(0, 9)) === input[9]);
  else if(input.length === 13) return (calculateISBN13CheckDigit(input.substring(0,12)) === Number(input[12]));
  return false;
}

function appendISBN10(input) {
  return input + calculateISBN10CheckDigit(input);
}
function appendISBN13(input) {
  return input + calculateISBN13CheckDigit(input);
}

function fixToValidISBN10(input = "") {
  if(!input) return "";
  if(input.length === 13 && input.startsWith("978") && validate(input)) {
    return appendISBN10(input.substring(3, 12));
  }
  if(input.length === 10 && validate(input)) {
    return input;
  }
  return "";
}
function fixToValidISBN13(input = "") {
  if(!input) return "";
  if(input.length === 10 && validate(input)) {
    return appendISBN13("978" + input.substring(0, 9));
  }
  if(input.length === 13 && validate(input)) {
    return input;
  }
  return "";
}


module.exports = {
  validate: validate,
  fixToValidISBN10: fixToValidISBN10,
  fixToValidISBN13: fixToValidISBN13,
}

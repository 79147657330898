import React, { useState, useContext } from 'react';
import booksapi from '../booksapi';
import { useNavigate } from "react-router-dom";

import {LibraryContext} from '../libraryContext';

function NewAuthorForm() {
  const navigate = useNavigate();
  const libraryID = useContext(LibraryContext);

  const [ state, setState ] = useState({authorForename: "", authorSurname: "", authorMiddleNames: "", authorDisplayName: ""});

  function handleChange(event) {
    const { name, value } = event.target;
    let newState = { ...state };
    newState[name] = value;
    setState(newState);
  }

  function handleSubmit(event) {
    console.log(event);
    console.log(state);
    event.preventDefault();

    booksapi.post('/authors', state, libraryID)
    .then((response) => {
      console.log(response);
      const newAuthor = response.data.author;
      navigate("/authors/" + newAuthor.authorID);

    }, (error) => {
      /** TODO handle errors */
      console.log(error);
    });
  }

  return (
    <>
    <h1>New author</h1>
    <form id="authorForm" className="booksForm" onSubmit={handleSubmit}>
      <label htmlFor='authorForename'>Forename</label>
      <input className='form-control mb-2' name='authorForename' placeholder='e.g. Pelham' type='text' id='addbook-forename' onChange={handleChange} data-lpignore="true"/>
      <label>Middle names</label>
      <input className='form-control mb-2' placeholder='e.g. Grenville' name='authorMiddleNames' type='text' id='addbook-middlenames' onChange={handleChange} data-lpignore="true"/>
      <label>Surname</label>
      <input className='form-control mb-2' placeholder='e.g. Wodehouse' name='authorSurname' type='text' id='addbook-surname' onChange={handleChange} data-lpignore="true"/>
      <label>Display name</label>
      <input className='form-control mb-2' placeholder='e.g. PG Wodehouse' name='authorDisplayName' type='text' id='addbook-displayName' onChange={handleChange} data-lpignore="true"/>
      <button type='submit' className='btn-primary btn-lg btn-block' value='Add Author'>Add author</button>
    </form>
    </>
  );
}

export default NewAuthorForm

import React, { useState, useEffect, useContext } from 'react';
import booksapi from '../booksapi';
import {
  Link
} from "react-router-dom";
import Emoji from '../Emoji';
import Spinner from './Spinner';
import { LibraryContext } from '../libraryContext';


const AuthorList = () => {
  const [ items, setItems ] = useState([]);
  const [ filter, setFilter ] = useState("");
  const [ isLoading, setLoaded ] = useState(true);
  const libraryID = useContext(LibraryContext);

  useEffect(() => {
    const fetchAuthors = async() => {
      const result = await booksapi.get('/authorsPlus', libraryID);
      console.log(result.data);
      setItems(result.data);
      setLoaded(false);
      document.title = "Books db - Authors";
    };
    fetchAuthors();
  }, [ libraryID]);

  const filterAuthors = (e) => {
    setFilter(e.target.value);
  }

  return (
    <div>
    {isLoading ? (<Spinner/>)
      : (<section>
        <h1>Authors</h1>
        <div>
          <label htmlFor='author-filter'>Filter author list</label>
          <input id='author-filter' type='text' onChange={filterAuthors} autoFocus/>
        </div>
        <div className='columns'>
          <ul className='list-unstyled'>
            {
              items.filter(item => filter.length === 0 || item.authorDisplayName.toUpperCase().indexOf(filter.toUpperCase()) > -1)
              .map(item =>
                (<li key={item.authorID}>
                  <Link className="link-dark text-decoration-none" to={`/authors/${item.authorID}`}>{item.authorDisplayName}</Link>&nbsp;
                  <span className='badge bg-secondary'><Emoji symbol="📚" label="books"/>{item.ownedCount} / {item.ownedEditionCount}</span>&nbsp;
                  <span className='badge bg-secondary light'><Link className="link-light text-decoration-none" tabIndex="-1" to={`/authors/${item.authorID}/addbook`}>+</Link></span>
                  </li>)
          )}
        </ul>
        </div>
      </section>)}
    </div>
  )
}

export default AuthorList

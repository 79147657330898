import React, { useState, useRef, useContext } from 'react';
import booksapi from '../booksapi';

import SearchPanel from './SearchPanel';
import {LibraryContext} from '../libraryContext';

const AuthorSearch = props => {
  const libraryID = useContext(LibraryContext);

  const getAuthors = async (search) => {
    const results = await booksapi.get('/search/authors?term=' + search, libraryID);
    const nextSearchResults = [];
    console.log(results.data);
    for(let author of results.data) {
      nextSearchResults.push({key: author.value, value: author.label});
    }
    return nextSearchResults;
  }

  return (
    <SearchPanel add={props.add} getNextSearchResults={getAuthors}/>
  );
}

export default AuthorSearch;

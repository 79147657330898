import React, { useState, useRef, useContext } from 'react';
import booksapi from '../booksapi';
import NewPublisherModal from './NewPublisherModal';
import {LibraryContext} from '../libraryContext';


const PublisherSearch = props => {

  const [ search, setSearch ] = useState(props.publisherName);
  const [ publishers, setPublishers ] = useState([]);
  const [ publisherSet, setPublisherSet ] = useState(false);
  const [ publisherValid, setPublisherValid ] = useState(props.publisherName !== "");
  const publisherInput = useRef();
  const publisherList = useRef();
  const searchPanel = useRef();
  const libraryID = useContext(LibraryContext);

  async function handleChange (e) {
    const value = e.target.value;
    setPublisherValid(false);
    setSearch(value);
    if(value.length >= 2) {
      const results = await booksapi.get('/search/publishers?term=' + value, libraryID);
      const nextPublishers = [];
      for(let publisher of results.data) {
        nextPublishers.push({publisherID: publisher.value, publisherName: publisher.label});
      }
      setPublishers(nextPublishers);
    }
    else {
      setPublishers([]);
    }
    scrollList();
  }

  function scrollList() {
    let first = publisherList.current.firstChild; // targets the first <li>
    searchPanel.current.onkeydown = function(e) { // listen to keyboard events
      switch (e.keyCode) {
        case 38: // if the UP key is pressed
          if (e.target === publisherInput.current) { break; }
          else if(document.activeElement === first) {
            publisherInput.current.focus();
          }
          else { document.activeElement.previousSibling.focus(); } // select the element before the current, and focus it

          e.preventDefault();
          break;
        case 40: // if the DOWN key is pressed
          if (document.activeElement === publisherInput.current && first !== null) { first.focus(); } // if the currently focused element is the main input --> focus the first <li>
          else if(document.activeElement.nextSibling) {
            document.activeElement.nextSibling.focus();
          }
          else {
            break;
          }

          e.preventDefault();
          break;
        case 13: // enter
          if(e.target.parentNode === publisherList.current) {
            e.target.firstChild.click();
            publisherInput.current.focus();
          }
          else if(e.target === publisherInput.current && publisherList.current.childElementCount === 1) {
            publisherList.current.firstChild.firstChild.click();
            publisherInput.current.focus();
          }
          e.preventDefault();
          break;
      }
    }
  }

  function pickPublisher(publisherID, publisherName) {
    props.add(publisherID, publisherName);
    setPublisherSet(true);
    setPublishers([]);
    setSearch(publisherName);
    setPublisherValid(true);
  }

  return (
    <div ref={searchPanel}>
      <input ref={publisherInput} className='form-control mb-2 w-50' style={{display: 'inline-block'}} type='text' id='publisherSearch' value={search} onChange={handleChange} autoComplete='off' readOnly={publisherSet} required='yes' autoFocus/>
      {publisherSet ? (<button className='btn-secondary btn' onClick={() => setPublisherSet(false)}>Change</button>) : false}
      <NewPublisherModal add={pickPublisher}/>
      <ul ref={publisherList} id='publishers' className='searchList'>
        {publishers.map((publisher, i) => {
          return (<li key={publisher.publisherID} tabIndex='0'><button onClick={() => pickPublisher(publisher.publisherID, publisher.publisherName)}>{publisher.publisherName}</button></li>)
        })}
      </ul>
    </div>
  );
}

export default PublisherSearch;

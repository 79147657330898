import React, { useState, useEffect, useContext } from 'react';
import booksapi from '../booksapi';
import {
  Link,
  useParams
} from "react-router-dom";
import {Helmet} from "react-helmet-async";
import Spinner from './Spinner';
import BookTile from './BookTile';
import EditionTile from './EditionTile';
import EditionForm from './EditionForm';
import BookForm from './BookForm';
import TagsGroup from './TagsGroup';
import EditButton from './EditButton';
import {LibraryContext} from '../libraryContext';

const Book = () => {
  const [ book, setBook ] = useState({});
  const [ isLoading, setLoaded ] = useState(true);
  const [ showAddEdition, setShowAddEdition ] = useState(false);
  const [ editBook, setEditBook ] = useState(false);
  const libraryID = useContext(LibraryContext);

  let { bookID } = useParams();

  const handleNewEdition = (newEdition) => {
    booksapi.post('/books/' + book.bookID + '/editions', newEdition, libraryID)
    .then((response) => {
      const newBookEditionID = response.data;
      newEdition.bookEditionID = newBookEditionID;

      let newBook = {...book};
      newBook.editions.push(newEdition);
      setBook(newBook);
      setShowAddEdition(false);
      // clear form, and hide by telling parent
    }, (error) => {
      // TODO handle errors
      console.log(error);
    });
  }
  const handleNewTag = (tagID, tag) => {
    console.log(libraryID + " ; " + tag);
    booksapi.post('/books/' + book.bookID + '/tags/' + tagID, {}, libraryID)
    .then((response) => {
      let newBook = {...book};
      newBook.tags.push({tagID: tagID, tag: tag});
      setBook(newBook);
      // clear form, and hide by telling parent
    }, (error) => {
      // TODO handle errors
      console.log(error);
    });
  }
  const handleRemoveTag = (tagID, tag) => {
    booksapi.delete('/books/' + book.bookID + '/tags/' + tagID, libraryID)
    .then((response) => {
      let newBook = {...book};
      newBook.tags = newBook.tags.filter(x => x.tagID !== tagID);
      setBook(newBook);
      // clear form, and hide by telling parent
    }, (error) => {
      // TODO handle errors
      console.log(error);
    });
  }
  const handleChangedEdition = async (newEdition, editionID) => {
    try {
      await booksapi.post('/books/' + bookID + '/editions/'  + editionID, newEdition, libraryID);

      let newBook = {...book};
      newBook.editions = newBook.editions.map(ed => ed.bookEditionID === editionID ? newEdition : ed);
      setBook(newBook);
    }
    catch (error) {
      console.log(error);
    }
  }

  const handleUpdatedEdition = async (newEdition, editionID) => {
    let newBook = {...book};
    newBook.editions = newBook.editions.map(ed => ed.bookEditionID === editionID ? newEdition : ed);
    setBook(newBook);
  }

  const handleNeWBook = async (state, authors) => {/**TODO */
    state.authors = authors;
    await booksapi.post('/books/' + bookID, state, libraryID);
    setBook(state);
    setEditBook(false);
  }

  useEffect(() => {
    const fetchBook = async () => {
      const result = await booksapi.get('/books/' + bookID, libraryID);
      setBook(result.data);
      setLoaded(false);
    };
    fetchBook();
  }, [ libraryID, bookID ]);

  return (
    <>
    {isLoading ? (<Spinner/>)
    : (
      <>
        <section>
        <h1>
          {book.title}
          <EditButton disabled={editBook} onClick={() => setEditBook(true)}/>
        </h1>
        <h3>{book.subTitle}</h3>

        {editBook ?
          (<BookForm book={book} authors={book.authors} onSubmit={handleNeWBook} onCancel={() => setEditBook(false)}/>) :
          (<>
            <h3>{book.authors.map(author => (<span key={author.authorID}><Link to={`/authors/${author.authorID}`}>{author.authorDisplayName}</Link></span>))}</h3>

            {book.firstPublished ? (<div className='firstPublished'>First published: {book.firstPublished}</div>) : false}
            {book.type ? (<div className='type'>Book type: {book.type}</div>) : false}
            {book.fiction ? (<div className='fiction'>Fiction: {book.fiction}</div>) : false}
            {book.subBooks.length > 0 ? (<div className='omnibus'>Omnibus edition</div>) : false}
            <TagsGroup tags={book.tags} deleteTag={handleRemoveTag} addTag={handleNewTag}/>
            {book.subBooks.length > 0 ? (
              <>
                <h3>Containing:</h3>
                <div className='subBooks cards'>
                  {book.subBooks.map(subBook => (
                    <BookTile key={subBook.bookID} book={subBook}/>
                  ))}
                </div>
              </>
            ) : false}
          </>)
        }

        <div className='editions'>
          <h3>Editions</h3>
          <div className='editions-tiles cards'>
            {book.editions.map(
              edition => (<EditionTile key={"" + edition.bookEditionID + "/" + bookID} key2={"" + edition.bookEditionID + "/" + bookID} edition={edition} handleChangedEdition={handleChangedEdition} handleUpdatedEdition={handleUpdatedEdition}/>)
            )}

          </div>
          <button className='btn-primary btn-lg' disabled={showAddEdition} onClick={() => setShowAddEdition(true)}>Add new edition</button>

          {showAddEdition ?
            (<EditionForm onSubmit={handleNewEdition} onCancel={() => setShowAddEdition(false)}/>)
            : ("")
          }
        </div>
      </section>

      <Helmet>
        <title>{book.title}</title>
      </Helmet>
      </>
    )}
    </>
  )
}

export default Book

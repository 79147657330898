import React from 'react';
import { Link } from "react-router-dom";

const BookTile = props => {
  let book = props.book;

  return (
    <>
      <div key={book.bookID} className='book card'>
        <div className="card-header">{book.title}</div>
        <div className='card-body'>
          <div className='book-title'><h5><Link to={`/books/${book.bookID}`}>{book.title ? book.title : "unknown"}</Link></h5></div>
          <div className='book-firstPublished'>First published: {book.firstPublished ? book.firstPublished : "unknown"}</div>
          {book.ISBN ? (<span className='edition-ISBN'>{book.ISBN}</span>) : false}
          {book.penguinNumber ? (<span className='edition-penguinNumber'>{book.penguinNumber}</span>) : false}
          {book.format ? (<span className='edition-format'>{book.format}</span>) : false}
        </div>
      </div>
    </>
  );
}

export default BookTile;

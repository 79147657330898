import React, { useState, useEffect, useContext } from 'react';
import booksapi from '../booksapi';
import {
  Link
} from "react-router-dom";
import {Helmet} from "react-helmet-async";
import Spinner from './Spinner';
import {LibraryContext} from '../libraryContext';


const TagList = () => {
  const [ tags, setTags ] = useState([]);
  const [ isLoading, setLoaded ] = useState(true);
  const libraryID = useContext(LibraryContext);

  useEffect(() => {
    const getTags = async() => {
      const result = await booksapi.get('/tags', libraryID);
      setTags(result.data);
      setLoaded(false);
    };
    getTags();
  }, [ libraryID ]);

  return (
    <div id='tags'>
      {isLoading ? (<Spinner/>)
        : (<>
          <h1>Tags</h1>
          <section>
            <ul>
              {tags.map(tag => (
                <li key={tag.tagID}><Link to={`/tags/` + tag.tagID}>{tag.tag}</Link></li>
              ))}
            </ul>

            <Helmet>
              <title>Tags</title>
            </Helmet>
          </section>
        </>)}
    </div>
  )
}

export default TagList

import React, { useState, useContext, useEffect } from 'react';

import { Link, useNavigate } from "react-router-dom";
import {LibraryContext} from '../libraryContext';

const Header = () => {
  const navigate = useNavigate();
  const libraryID = useContext(LibraryContext);
  let [ color, setColor ] = useState("rgb(253,187,48)");

  const isIos = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test( userAgent );
  }
  useEffect(() => {
    if(libraryID === 1) { setColor("hsla(184, 45%, 35%, 1)"); }
    else if(libraryID === 2) { setColor("rgb(253,187,48)");  }
    else { setColor("rgb(160,20,20)"); }
  }, [ libraryID ]);

  // Detects if device is in standalone mode
  const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);
  const [ showMenu, setShowMenu ] = useState(false);
  const [ showBack ] = useState(isIos() && isInStandaloneMode());

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  }
  const hideMenu = () => {
    setShowMenu(false);
  }

  const goBack = (e) => {
    navigate();
  }

  return (
  <header className="container-fluid" style={{backgroundColor: color}}>
    <nav className="navbar navbar-expand-sm navbar-dark" style={{backgroundColor: color}}>
      <div className="container-fluid">
        <Link className="navbar-brand" to="/" onClick={hideMenu}>MyBooks</Link>
        { showBack ? <button className="btn" onClick={goBack}>🔙</button> : "" }
        <button className="navbar-toggler" type="button" onClick={toggleMenu} aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className={"collapse navbar-collapse " + (showMenu ? "show" : "hide")} id="navbarTogglerDemo01">
          <ul className="navbar-nav mr-auto mb-2 mb-lg-0">
            <li className="nav-item"><Link className="nav-link link-light" to="/" onClick={hideMenu}>Home</Link></li>
            <li className="nav-item"><Link className="nav-link link-light" to="/authors" onClick={hideMenu}>Authors</Link></li>
            <li className="nav-item"><Link className="nav-link link-light" to="/collections" onClick={hideMenu}>Collections</Link></li>
            <li className="nav-item dropdown">
              <button className="button-link nav-link dropdown-toggle" href="#" id="navbarDropdown" data-toggle="dropdown" aria-expanded="false">
                Add
              </button>
              <ul className="dropdown-menu bg-dark" aria-labelledby="navbarDropdown">
                <li><Link className="nav-link link-light" to="/new/author" onClick={hideMenu}>author</Link></li>
                <li><Link className="nav-link link-light" to="/new/book" onClick={hideMenu}>book</Link></li>
                <li><Link className="nav-link link-light" to="/new/publisher" onClick={hideMenu}>publisher</Link></li>
                <li><Link className="nav-link link-light" to="/new/collection" onClick={hideMenu}>collection</Link></li>
                <li><Link className="nav-link link-light" to="/new/tag" onClick={hideMenu}>tag</Link></li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>);

}


export default Header;

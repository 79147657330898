import React, {useState, useEffect} from 'react';
import AuthorSearch from './AuthorSearch';
import TagSearch from './TagSearch';



const CollectionForm = props => {

    const tidyIncomingCollection = (e) => {
      console.log("XX");
      if(!e) e = {};


      if(!e.title) e.title = "";
      if(!e.criteria) e.criteria = {};
      if(!e.criteria.authors) e.criteria.authors = [];
      if(!e.criteria.bookTags) e.criteria.bookTags = [];
      if(!e.criteria.bookExcludeTags) e.criteria.bookExcludeTags = [];
      if(!e.criteria.editionTags) e.criteria.editionTags = [];
      if(!'omnibusFilter' in e.criteria) e.criteria.omnibusFilter = e.criteria.omnibus === false || e.criteria.omnibus === true;
      if(!e.criteria.omnibus) e.criteria.omnibus = false;
      if(!e.criteria.penguin) e.criteria.penguin = false;
      if(!e.criteria.publishedAfter) e.criteria.publishedAfter = null;
      if(!e.criteria.publishedBefore) e.criteria.publishedBefore = null;

      if(!e.editionTags) e.editionTags = [];
      e.editionTags = e.editionTags.map(tag => { if(!('required' in tag)) tag.required = false; return tag;});
      return e;
    }

    const [ state, setState ] = useState(tidyIncomingCollection(props.collection));

    const handleChangeTitle = (event) => {
      const { name, value, checked, type } = event.target;
      let newState = { ...state };
      if(type === 'checkbox') {
        newState[name] = checked;
      }
      else {
        newState[name] = value
      }
      setState(newState);
      console.log(newState);
    }

    const handleChange = (event) => {
      const { name, value, checked, type } = event.target;
      console.log(event.target);
      let newState = { ...state };
      if(type === 'checkbox') {
          newState.criteria[name] = checked;
      }
      else {
        newState.criteria[name] = value
      }
      setState(newState);
      console.log(newState);
    }
    const handleSubmit = (e) => {e.preventDefault();
      if(!state.criteria.omnibusFilter) state.criteria.omnibus = null;
      props.onSubmit(state);
      console.log(state);
    }
    const handleCancel = (e) => {e.preventDefault(); props.onCancel();/**TODO */}

    const updateState = (f) => {
      let newState = {...state};
      f(newState);
      setState(newState);
    }

    const handleNewAuthor = (authorID, authorDisplayName) => updateState(state => {state.criteria.authors.push({authorID: authorID, authorDisplayName: authorDisplayName }); });
    const removeAuthor = i => updateState(state => {state.criteria.authors.splice(i, 1);});
    const removeBookTag = (tagID, tag) => updateState(state => {state.criteria.bookTags = state.criteria.bookTags.filter(x => x.tagID !== tagID);});
    const handleNewBookExcludeTag= (tagID, tag) => updateState(state => {state.criteria.bookExcludeTags.push({tagID: tagID, tag: tag}); });
    const removeBookExcludeTag = (tagID, tag) => updateState(state => {state.criteria.bookExcludeTags = state.criteria.bookExcludeTags.filter(x => x.tagID !== tagID); });
    const handleNewBookEditionTag= (tagID, tag) => updateState(state => {state.criteria.editionTags.push({tagID: tagID, tag: tag}); });
    const removeBookEditionTag = (tagID, tag) => updateState(state => {state.criteria.editionTags = state.criteria.editionTags.filter(x => x.tagID !== tagID); });
    const handleNewEditionTag = (tagID, tag) => updateState(state => {state.editionTags.push({tagID: tagID, tag: tag, required: false}); });
    const removeEditionTag = (tagID, tag) => updateState(state => {state.editionTags = state.editionTags.filter(x => x.tagID !== tagID);});

    const toggleEditionTagRequired = (event, tagIndex) => { event.preventDefault(); updateState(state => {state.editionTags[tagIndex].required = !state.editionTags[tagIndex].required; }); };

  return (
    <>
    <form id='addcollection-form' className="collectionForm" onSubmit={handleSubmit}>
      <button name='submit2' id='xxx' type='submit' className='defaultsink' value='Add Collection'/>
      <label htmlFor='title'>Title</label>
      <input className='form-control mb-2' name='title' type='text' id='title' value={state.title} onChange={handleChangeTitle}/>


      <label htmlFor='title'>Author(s)</label>
      <div>
        {state.criteria.authors.map((author, i) => {
          return (
            <span key={author.authorID}>{author.authorDisplayName} <button className='btn btn-secondary' onClick={() => removeAuthor(i)}>X</button></span>
          )
        })}
      </div>
      <AuthorSearch add={handleNewAuthor}/>

      <label htmlFor='bookTags'>Book Tag(s)</label>
      <div className='tags'>
        {state.criteria.bookTags.map(tag => (
          <span className='tag' key={tag.tagID}>{tag.tag} <button onClick={() => removeBookTag(tag.tagID, tag.tag)}>x</button></span>
        ))}
        <TagSearch add={(tagID, tag) => updateState(state => {state.criteria.bookTags.push({tagID: tagID, tag: tag}); })}/>
      </div>

      <label htmlFor='bookExcludeTags'>Exclude Book Tag(s)</label>
      <div className='tags'>
        {state.criteria.bookExcludeTags.map(tag => (
          <span className='tag' key={tag.tagID}>{tag.tag} <button onClick={() => removeBookExcludeTag(tag.tagID, tag.tag)}>x</button></span>
        ))}
        <TagSearch add={handleNewBookExcludeTag}/>
      </div>


      <label htmlFor='publishedAfter'>Published After</label>
      <input className='form-control mb-2' name='publishedAfter' type='number' min='1500' max='2050' id='publishedAfter' value={state.criteria.publishedAfter} onChange={handleChange}/>

      <label htmlFor='publishedBefore'>Published Before</label>
      <input className='form-control mb-2' name='publishedBefore' type='number' min='1500' max='2050' id='publishedBefore' value={state.criteria.publishedBefore} onChange={handleChange}/>

      <label htmlFor='penguin'>Penguin?</label>
      <div className='form-check form-switch'>
        <input className='form-check-input mb-2' name='penguin' type='checkbox' id='penguin' checked={state.criteria.penguin} onChange={handleChange}/>
      </div>

      <label htmlFor='omnibus'>Filter omnibus?</label>

      <div className='form-check form-switch'>{state.criteria.omnibusFilter ? 1 : 0}
        <input className='form-check-input mb-2' name='omnibusFilter' type='checkbox' id='omnibusFilter' checked={state.criteria.omnibusFilter} onChange={handleChange}/>
      </div>
      <div className='form-check form-switch'>
        Exclude<input className='form-check-input mb-2' disabled={!state.criteria.omnibusFilter} name='omnibus' type='checkbox' id='omnibus' checked={state.criteria.omnibus} onChange={handleChange}/>Include
      </div>

      <label htmlFor='bookTags'>Books with edition Tag(s)</label>
      <div className='tags'>
        {state.criteria.editionTags.map(tag => (
          <span className='tag' key={tag.tagID}>{tag.tag} <button onClick={() => removeBookEditionTag(tag.tagID, tag.tag)}>x</button></span>
        ))}
        <TagSearch add={handleNewBookEditionTag}/>
      </div>

      <label htmlFor='editionTags'>Edition Tag(s)</label>
      <div className='tags'>
        {state.editionTags.map((tag, i) => (
          <span className='tag' key={tag.tagID}>{tag.tag} <button onClick={(e) => toggleEditionTagRequired(e, i)}>{tag.required ? "REQ" : "NOT REQ"}</button><button onClick={() => removeEditionTag(tag.tagID, tag.tag)}>x</button></span>
        ))}
        <TagSearch add={handleNewEditionTag}/>
      </div>
      <button className='btn-primary btn-lg btn-block' name='cancel' value='Cancel' onClick={handleCancel}>Cancel</button>
      <button className='btn-primary btn-lg btn-block' name='submit' type='submit' value='Add Book' onClick={handleSubmit}>Save</button>
      </form>
    </>);
}

export default CollectionForm;

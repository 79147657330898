import React from 'react';
import { useRouteMatch,  Link } from "react-router-dom";
import LibraryStats from './LibraryStats';

import XButton from './XButton';
import EditButton from './EditButton';
import PlusButton from './PlusButton';

const HomePage = (props) => {
  return (
    <>
      <h1>MyBooks</h1>
      <button onClick={() => props.setLibraryID(1)}>Charlie</button>
      <button onClick={() => props.setLibraryID(2)}>Daisy</button>
      <h2>{props.libraryID}</h2>
      <ul>
        <li><Link to="/authors">List authors</Link></li>
        <li><Link to="/collections">Show collections</Link></li>
        <li><Link to="/tags">Show tags</Link></li>
        <li>
          Add new:
          <ul>
            <li><Link to="/new/author">author</Link></li>
            <li><Link to="/new/book">book</Link></li>
            <li><Link to="/new/publisher">publisher</Link></li>
            <li><Link to="/new/collection">collection</Link></li>
            <li><Link to="/new/tag">tag</Link></li>
          </ul>
        </li>
      </ul>
      <LibraryStats libraryID={props.libraryID}/>
    </>
  )
}

export default HomePage

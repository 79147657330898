import React, { useContext } from 'react';
import booksapi from '../booksapi';
import { useNavigate } from "react-router-dom";
import TagForm from './TagForm';
import {LibraryContext} from '../libraryContext';

const NewTagForm = props => {
  const navigate = useNavigate();
  const libraryID = useContext(LibraryContext);

  const handleNewTag = async (tag) => {
    try {
      let response = await booksapi.post('/tags', tag, libraryID);
      const newTag = response.data;
      console.log(newTag);
      navigate(-1);
    }
    catch(error) {
      // TODO handle errors
      console.log(error);
    };
  }

  return ( <TagForm onSubmit={handleNewTag} onCancel={(e) => { navigate(-1) }}/>  );
}

export default NewTagForm

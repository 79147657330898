import axios from 'axios';
import config from './config.json';


export const get = async (path, libraryID) => {
  if(!libraryID) {
    console.log("Missing libraryID on path : " + path);
  }
  return await axios.get(config.apiroot + path, { headers: {libraryID: libraryID}});
}
export const post = async (path, body, libraryID) => {
  if(!libraryID) {
    console.log("Missing libraryID on path : " + path);
  }
  return await axios.post(config.apiroot + path, body, { headers: {libraryID: libraryID}});
}
export const del = async (path, libraryID) => {
  if(!libraryID) {
    console.log("Missing libraryID on path : " + path);
  }
  return await axios.delete(config.apiroot + path, { headers: {libraryID: libraryID}});
}

const exports = {
  get: get,
  post: post,
  delete: del,
}

export default exports;
